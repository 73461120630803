<template>
  <div class="image-compare-wrapper">
    <div class="image-compare" ref="imageCompare">
      <img :src="`img/${image1}`" />
      <img :src="`img/${image2}`" />
    </div>
  </div>
</template>

<script>
import ImageCompare from "image-compare-viewer";
export default {
  name: 'ImageComparison',
  props: [
    "image1",
    "image2",
    "start"
  ],
  data: () => ({
    element: null,
    viewer: null,
    options: {
      // UI Theme Defaults
      controlColor: "#FFFFFF",
      controlShadow: true,
      addCircle: false,
      addCircleBlur: true,

      // Label Defaults
      showLabels: false,
      labelOptions: {
        before: 'Before',
        after: 'After',
        onHover: false
      },

      // Smoothing
      smoothing: true,
      smoothingAmount: 100,

      // Other options
      hoverStart: false,
      verticalMode: false,
      startingPoint: 75,
      fluidMode: false
    }
  }),
  methods: {
    init() {
      this.options.startingPoint = this.start;
      this.element = this.$refs.imageCompare;
      this.viewer = new ImageCompare(this.element, this.options).mount();
    }
  },
  mounted() {
    this.init();
  }
}
</script>

<style lang="scss" scoped>
.image-compare-wrapper {
  display: block;
}
</style>
