<template>
  <base-section id="what-it-will-look-like" class="bac-section">
    <base-section-heading title="What It Would Look Like" class="mt-8">
      These are some mockups of what it appears the new construction would look
      like (based on the architectural plans submitted by the Marina to the
      Conservation Commission and Planning Board, as&nbsp;well as renderings they
      made available to the Old Colony Memorial newspaper).<br /><br />Drag the
      arrows back and forth to compare the Marina's proposal to what it looks like now.
    </base-section-heading>
    <v-responsive class="mx-auto" max-width="1200">
      <v-container fluid>
        <v-row>
          <v-col style="max-width: 900px; margin: 0 auto;">
            <image-compare
              image1="foundry-proposed.png"
              image2="foundry-now.png"
              :start="75"
            ></image-compare>
            <image-compare
              image1="corner-proposed.jpg"
              image2="corner-now.jpg"
              :start="30"
              class="mt-5"
            ></image-compare>
            <image-compare
              image1="gap-proposed.png"
              image2="gap-now.png"
              :start="50"
              class="mt-5"
            ></image-compare>
            <!-- <image-compare
              image1="front-proposed.png"
              image2="front-now.png"
              :start="80"
              class="mt-5"
            ></image-compare> -->
            <image-compare
              image1="Union-Street-proposed.jpg"
              image2="Union-Street-now.jpg"
              :start="20"
              class="mt-5"
            ></image-compare>
          </v-col>
        </v-row>
      </v-container>
    </v-responsive>
  </base-section>
</template>

<script>
import ImageCompare from "../../components/ImageCompare.vue";
export default {
  components: { ImageCompare },
  name: "WhatItWillLookLike",

  data: () => ({}),
  methods: {}
};
</script>

<style lang="scss">
#what-it-will-look-like {
  background-color: #caf0f8;
  padding: 2em 1em !important;
}
</style>
